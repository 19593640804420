import { User } from "@firebase/auth";
import patientApi from "@mgdx/api/lib/patientApi";
import { PatientProviderTypeEnum, PatientUpdateStatusEnum } from "@mgdx/shared/src/models/Patient";
import { PatientStatusEnum } from "@mgdx/shared/src/models/Patient";
import { disableTalk } from "@mgdx/talk/ducks";
import {
  apiErrorHandler,
  ApiNotFoundError,
  errorHandlerReport,
  firebaseErrorHandler,
  isFirebaseError,
} from "@mgdx-libs/error-handler";
import { clearAccessToken, FirebaseUserRoleNoneError, getFirebaseAuth, setAccessToken } from "@mgdx-libs/firebase";
import { navigate } from "@mgdx-libs/link";
import { logger } from "@mgdx-libs/logger";
import { clearPersist } from "@mgdx-libs/redux-persist-ssr";
import { useLocation } from "@reach/router";
import { abortRequests, clearRequestsCache, resetRequests } from "@redux-requests/core";
import { EnhancedStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import React, { PropsWithChildren, useCallback, useState } from "react";

import { setIsInitialAuthCheckCompleted, useIsInitialAuthCheckCompleted } from "./ducks/authState";
import { clearCurrentUser, setCurrentUserWithDispatch } from "./ducks/currentUser";
import { getInProcessOfOIDCSignup } from "./hooks/useInProcessOfOIDCSignup";
import { FirebaseAuthObserver, FirebaseAuthProvider } from "./providers/FirebaseAuthProvider";
import { ErrorForMccmApp } from "./ui/ErrorForMccmApp";
import { PreloaderForMccm } from "./ui/PreloaderForMccm";
import { FullscreenCoverPreloader } from "./ui/PreloaderForMccm/FullscreenCoverPreloader";
import { canUseAppBridge } from "./utils/app-bridge/canUseAppBridge";
import { getAppNetUserIdFromApp, getAppUserIdFromApp, getIsInMccmApp } from "./utils/app-bridge/getFromApp";
import { callShowBackButtonInApp, isCallableGetAppVersion } from "./utils/app-bridge/publisher";
import {
  clearTokenAndState,
  getPatientFromFirebaseUserAndSetToStorage,
  signoutAndClearToken,
  updatePatientToEmailVerifiedAndSetToStore,
  updatePatientWithMccmAppUserId,
} from "./utils/user";
import { setIsInMccmApp } from "./utils/user-agent/isInMccmApp";

const AuthWithEmailExisting: React.FC<PropsWithChildren & { store: EnhancedStore }> = ({ children, store }) => {
  const dispatch = store.dispatch;

  const firebaseAuthObserver = useCallback(
    async (firebaseUser: User | null) => {
      logger.debug("observer's auth type: email existing");

      if (firebaseUser) {
        logger.debug("firebase.User: %o", firebaseUser);
        const result = await firebaseUser.getIdTokenResult().catch(async (tokenError) => {
          if (isFirebaseError(tokenError)) {
            try {
              firebaseErrorHandler(tokenError);
            } catch (e) {
              await errorHandlerReport(e as Error);
            }
          } else {
            await errorHandlerReport(tokenError);
          }
          return undefined;
        });

        if (result === undefined) {
          await navigate("/sign-out/", { replace: true });
          return;
        }

        if (result.claims.role !== "patient") {
          logger.debug("firebase.User: different role");
          return;
        }

        logger.debug("getIdTokenResult: %o", result);
        setAccessToken(result.token);

        logger.debug("firebase.user.emailVerified: %s", firebaseUser.emailVerified);
        const patient = await patientApi.getCurrentPatient().catch(async (errorOrResponse) => {
          if (errorOrResponse instanceof Response) {
            await getFirebaseAuth().signOut();
            dispatch(disableTalk());
            dispatch(abortRequests());
            dispatch(clearRequestsCache());
            dispatch(resetRequests());
            dispatch(clearCurrentUser());
            clearAccessToken();
            clearPersist();
            try {
              throw await apiErrorHandler(errorOrResponse);
            } catch (e) {
              await errorHandlerReport(e);
            }
          } else {
            if (errorOrResponse instanceof DOMException) {
              try {
                throw await apiErrorHandler(errorOrResponse);
              } catch (e) {
                await errorHandlerReport(e as Error);
              }
            } else if (errorOrResponse instanceof Error) {
              await errorHandlerReport(errorOrResponse);
            }
          }

          return undefined;
        });

        if (!patient) return;

        setCurrentUserWithDispatch(dispatch, patient);

        const isEmailVerifiedStatus = patient.status === PatientStatusEnum.Verified;
        logger.debug("patient.status.emailVerified: %s", isEmailVerifiedStatus);

        if (
          firebaseUser.emailVerified !== isEmailVerifiedStatus &&
          patient.loginProviderType === PatientProviderTypeEnum.Email
        ) {
          if (firebaseUser.emailVerified) {
            logger.debug("update patient.status: %s", PatientUpdateStatusEnum.Verified);
            await patientApi
              .putPatientStatus({
                putPatientStatusRequestBody: {
                  status: PatientUpdateStatusEnum.Verified,
                },
              })
              .then((patient) => {
                setCurrentUserWithDispatch(dispatch, patient);
              })
              .catch(async (errorOrResponse) => {
                if (errorOrResponse instanceof Response) {
                  try {
                    throw await apiErrorHandler(errorOrResponse);
                  } catch (e) {
                    await errorHandlerReport(e as Error);
                  }
                } else if (errorOrResponse instanceof Error) {
                  await errorHandlerReport(errorOrResponse);
                }
              });
          }
        }
      } else {
        dispatch(disableTalk());
        dispatch(clearCurrentUser());
        clearAccessToken();
        clearPersist();
      }
    },
    [dispatch]
  );

  const observer: FirebaseAuthObserver = useCallback(
    async (firebaseUser) => {
      await firebaseAuthObserver(firebaseUser);
    },
    [firebaseAuthObserver]
  );

  return <FirebaseAuthProvider observer={observer}>{children}</FirebaseAuthProvider>;
};

// memo: firebaseAuthObserverと同様の処理を行っている
const AuthWithEmail: React.FC<PropsWithChildren & { store: EnhancedStore }> = ({ children, store }) => {
  const dispatch = store.dispatch;

  const firebaseAuthWithEmail = useCallback(
    async (firebaseUser: User | null) => {
      logger.debug("observer's auth type: email");
      if (getInProcessOfOIDCSignup()) return;

      if (!firebaseUser) {
        dispatch(disableTalk());
        dispatch(clearCurrentUser());
        clearAccessToken();
        clearPersist();
        return;
      }

      const patient = await getPatientFromFirebaseUserAndSetToStorage(firebaseUser, dispatch).catch(async (error) => {
        logger.error(error);
        await signoutAndClearToken(dispatch);
        return undefined;
      });

      if (!patient) {
        navigate("/sign-in");
        return;
      }

      logger.debug("firebase.user.emailVerified: %s", firebaseUser.emailVerified);

      const isPatientEmailVerifield = patient.status === PatientStatusEnum.Verified;
      logger.debug("patient.status.emailVerified: %s", isPatientEmailVerifield);

      if (patient.loginProviderType !== PatientProviderTypeEnum.Email) return;

      if (firebaseUser.emailVerified && !isPatientEmailVerifield) {
        logger.debug("update patient.status: %s", PatientUpdateStatusEnum.Verified);
        await updatePatientToEmailVerifiedAndSetToStore(dispatch).catch(() => {
          return;
        });
      }
    },
    [dispatch]
  );

  const observer: FirebaseAuthObserver = useCallback(
    async (firebaseUser) => {
      await firebaseAuthWithEmail(firebaseUser);
      dispatch(setIsInitialAuthCheckCompleted(true));
    },
    [dispatch, firebaseAuthWithEmail]
  );

  return <FirebaseAuthProvider observer={observer}>{children}</FirebaseAuthProvider>;
};

const authSkipPathList = ["/oidc/matsukiyococokara", "/sign-out", "/settings/leave"];

const AuthInMccmApp: React.FC<PropsWithChildren & { store: EnhancedStore }> = ({ children, store }) => {
  const location = useLocation();
  const dispatch = store.dispatch;
  const [isError, setIsError] = useState<boolean>(false);

  const isInSkipAuthPage = authSkipPathList.some((path) => location.pathname.startsWith(path));
  const shouldSkipAuth = isInSkipAuthPage || getInProcessOfOIDCSignup();

  const firebaseAuthWithMccmOidc = useCallback(
    async (firebaseUser: User | null) => {
      logger.debug("observer's auth type: mccm oidc");

      // TODO: デバッグ完了したら消す
      console.log("path", location.pathname);
      console.log("isInSkipAuthPage", isInSkipAuthPage);
      console.log("shouldSkipAuth", shouldSkipAuth);

      if (shouldSkipAuth) {
        dispatch(setIsInitialAuthCheckCompleted(true));
        return;
      }

      const redirectAndStartOIDCAuth = async () => {
        await navigate("/oidc/matsukiyococokara/redirect/?redirect_url=" + encodeURIComponent(window.location.href), {
          replace: true,
        });
      };

      if (!firebaseUser) {
        clearTokenAndState(dispatch);
        clearPersist();
        await redirectAndStartOIDCAuth();

        return;
      }

      const patient = await getPatientFromFirebaseUserAndSetToStorage(firebaseUser, dispatch).catch(async (error) => {
        if (error instanceof ApiNotFoundError || error instanceof FirebaseUserRoleNoneError) {
          return undefined;
        }
        logger.error(error);
        throw error;
      });

      if (!patient) {
        await signoutAndClearToken(dispatch);
        await redirectAndStartOIDCAuth();
        return;
      }

      const yqbNetUserId = firebaseUser.uid.match(/oidc:matsukiyococokara-yqb:(\w+)/)?.[1];

      if (canUseAppBridge()) {
        // memo: アプリで調剤利用時に、アクセスして最初に1回だけ実行できればOKな処理
        // patient取得後でないと実行できないためここ（と新規利用開始、既存患者紐付け）で実行する。
        // ここは通常利用していれば調剤アクセス時１度しか呼ばれないはず
        getAppUserIdFromApp(async ({ appUserId }) => {
          await updatePatientWithMccmAppUserId(dispatch, patient, appUserId);
        });

        getAppNetUserIdFromApp(async ({ netUserId }) => {
          if (netUserId !== yqbNetUserId) {
            await signoutAndClearToken(dispatch);
            redirectAndStartOIDCAuth();

            if (process.env.BUILD_ENV === "dev" || process.env.BUILD_ENV === "stg") {
              Sentry.captureMessage("debug: NetUserID不一致");
            }
          } else {
            callShowBackButtonInApp();
            dispatch(setIsInitialAuthCheckCompleted(true));

            if (process.env.BUILD_ENV === "dev" || process.env.BUILD_ENV === "stg") {
              Sentry.captureMessage("debug: ログイン済み");
            }
          }
        });
      } else {
        const error = new Error("unexpected: can't use app bridge in daifuku app");
        logger.error(error.message);
        logger.debug("yqbNetUserId: %s", yqbNetUserId);

        throw error;
      }
    },
    [dispatch, isInSkipAuthPage, location.pathname, shouldSkipAuth]
  );

  const observer: FirebaseAuthObserver = useCallback(
    async (firebaseUser) => {
      await firebaseAuthWithMccmOidc(firebaseUser).catch(async (error) => {
        errorHandlerReport(error);

        setIsError(true);
      });
    },
    [firebaseAuthWithMccmOidc]
  );

  return <FirebaseAuthProvider observer={observer}>{isError ? <ErrorForMccmApp /> : children}</FirebaseAuthProvider>;
};

export const AuthProvider: React.FC<PropsWithChildren & { store: EnhancedStore }> = ({ children, store }) => {
  const isInitialAuthCheckCompleted = useIsInitialAuthCheckCompleted();
  const location = useLocation();
  const shouldWaitAuthCheckComplete =
    location.pathname.startsWith("/j") ||
    location.pathname.startsWith("/oidc/matsukiyococokara") ||
    new RegExp("^/pharmacy-counselings/\\d+/accept/").test(location.pathname);

  switch (process.env.MGDX_APP_NAME) {
    case "matsukiyococokara-yqb":
      if (isCallableGetAppVersion()) {
        getIsInMccmApp((isInMccmApp) => {
          setIsInMccmApp(isInMccmApp);
        });

        return (
          <AuthInMccmApp store={store}>
            {isInitialAuthCheckCompleted ? (
              children
            ) : shouldWaitAuthCheckComplete ? (
              <PreloaderForMccm />
            ) : (
              <FullscreenCoverPreloader>{children}</FullscreenCoverPreloader>
            )}
          </AuthInMccmApp>
        );
      } else {
        return (
          <AuthWithEmail store={store}>
            {isInitialAuthCheckCompleted ? (
              children
            ) : shouldWaitAuthCheckComplete ? (
              <PreloaderForMccm />
            ) : (
              <FullscreenCoverPreloader>{children}</FullscreenCoverPreloader>
            )}
          </AuthWithEmail>
        );
      }

    default:
      return <AuthWithEmailExisting store={store}>{children}</AuthWithEmailExisting>;
  }
};
